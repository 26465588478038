/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:93f3cece-9169-41f6-be94-707f4342bba5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Xjr2NMUsw",
    "aws_user_pools_web_client_id": "4emi3dlrak8d5ugjjd69uvo1ee",
    "oauth": {},
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "baconbitsdynamodb-prod",
            "region": "us-east-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "baconbitsapi",
            "endpoint": "https://f89wm0sis0.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_mobile_analytics_app_id": "be044913a4574f5ba992365717b02da1",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
